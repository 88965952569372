import {getSvgPath} from "figma-squircle";
const vSmoothCorners = {
    mounted(el, binding) {
        if (binding.value === false) return;
        setSvg(el, binding.value);
        el.smoothCornerObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                setSvg(entry.target, binding.value);
            }
        });

        el.smoothCornerObserver.observe(el);
    },
    beforeUnmount(el) {
        el.smoothCornerObserver?.disconnect();
    }
};

export default vSmoothCorners;

function setSvg(target, options){
    const svg = getSvgPath({
        width: target.offsetWidth,
        height: target.offsetHeight,
        cornerRadius: 20, // defaults to 0
        cornerSmoothing: 1, // cornerSmoothing goes from 0 to 1
        ...options
    });
    target.style.clipPath = `path('${svg}')`;
}
